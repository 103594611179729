.telegram-preview {
    p {
        margin: 0 !important;
        padding: 0;
        display: block;
        word-wrap: break-word;
        word-break: break-word;
        white-space: pre-line;
        overflow-wrap: break-word;
        line-height: 1.5;
    }

    a {
        word-break: break-all;
        display: inline-block;
        max-width: 100%;
    }
}