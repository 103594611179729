.OverlayLoadingComponent {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .LoadingIconComponent {
    width: 3%;
    top: unset;
  }
}
